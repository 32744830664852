
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { Contact, ContactPayments } from '../../../models/contacts.models';
import { retry } from 'rxjs/operators';
import { sortBy } from '../../../utils/sortby';
import { SystemService } from './system.service';
import { IMap, PositionedItem } from '../../../models/generic.models';
import { arraytomap, maptoarray } from '../../../utils/mapandarray';
import { HttpClient } from '@angular/common/http';
import { CustomToastrService } from 'src/app/shared/services/customToastr.service';
import { getNonNullNonEmptyValue } from 'src/app/utils/getNonNullValue';
import { ProfileUpdateVM } from '../../../models/contactUpdate.model';
import { environment } from '../environments/environment';
import { CustomMessageService } from './shared/services/customPriemngMessage.service';

const BASE_API_URL = environment.apiURL;
const MAX_API_CALL_RETRIES = environment.maxAPICallRetries;
@Injectable( { providedIn: 'root' } )
export class ContactsService {
	public contacts: BehaviorSubject<IMap<Contact>> = new BehaviorSubject( {} );
	public contactArray: BehaviorSubject<Contact[]> = new BehaviorSubject( [] );
	public contact: BehaviorSubject<Contact> = new BehaviorSubject( <Contact> {} );
	public contactID: BehaviorSubject<string> = new BehaviorSubject( '' );
	public contactFunctions: BehaviorSubject<IMap<PositionedItem>> = new BehaviorSubject( {} );
	public contactFunctionsArray: BehaviorSubject<PositionedItem[]> = new BehaviorSubject( [] );
	private subs: Subscription[] = [];

	constructor (
		private ss: SystemService,
		private http: HttpClient,
		private toasterService: CustomToastrService,
		private messageService: CustomMessageService,
	) {
		ss.isActive.subscribe( user => {
			if ( !user ) {
				this.subs.forEach( subscription => {
					subscription.unsubscribe();
				} );
			} else {
				this.contactsGet();
				this.contactFunctionsGet();
				this.subs.push( this.ss.url.subscribe( async ( url ) => {
					const segments = url.split( '/' );
					if ( segments[ 1 ] === 'contacts' && segments[ 2 ] ) {
						this.contactID.next( segments[ 2 ] );
						this.contact.next( await this.getContactbyId( segments[ 2 ] ) );
					}
				} ) );
			}
		} );
	}

	public contactsGet = async () => {

		const contacts = await this.contactsFetch();
		this.contactArray.next( contacts.sort( sortBy( 'displayName' ) ) );
		this.contacts.next( arraytomap( contacts ) );

	}

	private contactFunctionsGet = async () => {

		const contactFunctions = await this.contactFunctionsFetch();
		this.contactFunctionsArray.next( contactFunctions.sort( sortBy( 'position' ) ) );
		this.contactFunctions.next( arraytomap( contactFunctions ) );

	}

	private contactsFetch = async () => {

		try {
			return await firstValueFrom( this.http.get<Contact[]>( `${ BASE_API_URL }/contact` ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
		} catch ( error ) {
			this.toasterService.error( 'Failed to fetch the contacts from the server' );
			console.error( error );
			throw error;
		}

	}

	private contactFunctionsFetch = async () => {
		try {
			return await firstValueFrom( this.http.get<PositionedItem[]>( `${ BASE_API_URL }/contactReferences/function` ).pipe(
				retry( MAX_API_CALL_RETRIES ) ) );
		} catch ( error ) {
			this.toasterService.error( 'Failed to fetch the contact Functions from the server' );
			console.error( error );
			throw error;
		}

	}

	public contactDelete = async ( contactId: string ) => {

		if ( !contactId ) {
			this.toasterService.warning( 'contact delete is requested without a contact ID' );
			throw new Error( 'contact delete is requested without a contact ID' );
		}

		try {
			await firstValueFrom( this.http.delete( `${ BASE_API_URL }/contact/${ contactId }` ) );
			const Contacts = this.contacts.getValue();
			if ( Contacts[ contactId ] ) {
				delete Contacts[ contactId ];
				this.contacts.next( Contacts );
				this.contactArray.next( maptoarray( Contacts ).sort( sortBy( 'name', false, false ) ) );
			}
		} catch ( error ) {
			this.toasterService.error( 'Failed to delete the contact' );
			console.error( error );
			throw error;

		}
		return true;

	}
	public contactUpdate = async ( contactId: string, contactPayload ) => {

		if ( !contactId ) {
			this.toasterService.warning( 'Contact update is requested without a contact ID' );
			throw new Error( 'Contact update is requested without a contact ID' );
		}
		if ( contactPayload?.emails?.length > 0 ) {
			if ( contactPayload?.emails?.length > 0 ) {
				contactPayload.emails = contactPayload.emails.filter( email => email.email !== '' );
			}
		}
		try {
			await firstValueFrom( this.http.put( `${ BASE_API_URL }/contact/${ contactId }`, contactPayload ) );
			await this.syncUpdateCache( contactId, contactPayload );
			this.contactsGet();
			this.toasterService.success( 'profile saved' );

		} catch ( error ) {
			this.toasterService.error( 'Failed to update the contact' );
			console.error( error );
			throw error;
		}

		return true;

	}

	public contactUpdateMsg = async ( contactPayload ) => {
		if ( !contactPayload.id ) {
			this.toasterService.warning( 'Contact update is requested without a contact ID' );
			throw new Error( 'Contact update is requested without a contact ID' );
		}
		try {
			await firstValueFrom( this.http.put( `${ BASE_API_URL }/contactShowMsgPut`, contactPayload ) );
			await this.syncUpdateCache( contactPayload.id, contactPayload );
			this.toasterService.success( 'profile saved' );
		} catch ( error ) {
			this.toasterService.error( 'Failed to update the contact' );
			console.error( error );
			throw error;
		}

	}

	public contactPartial = async ( contactPayload, msg?) => {
		if ( !contactPayload.id ) {
			this.toasterService.warning( 'Contact update is requested without a contact ID' );
			throw new Error( 'Contact update is requested without a contact ID' );
		}
		if ( contactPayload.emails.length > 0 ) {
			if ( contactPayload.emails.length > 0 ) {
				contactPayload.emails = contactPayload.emails.filter( email => email.email !== '' );
			}
		}
		try {
			if ( msg ) {
				await firstValueFrom( this.http.put( `${ BASE_API_URL }/contactShowMsgPut`, contactPayload ) );
				await this.syncUpdateCache( contactPayload.id, contactPayload );
				this.toasterService.success( 'profile saved' );
			} else {
				await firstValueFrom( this.http.put( `${ BASE_API_URL }/contactPartialPut`, contactPayload ) );
				await this.syncUpdateCache( contactPayload.id, contactPayload );
				this.toasterService.success( 'profile saved' );
			}
		} catch ( error ) {
			this.toasterService.error( 'Failed to update the contact' );
			console.error( error );
			throw error;
		}

		return true;

	}
	private syncUpdateCache = async ( contactId: string, contactPayload: Partial<Contact> ) => {

		const Contacts = this.contacts.getValue();
		if ( Contacts[ contactId ] ) {
			Contacts[ contactId ] = contactPayload;
			this.contacts.next( Contacts );
			this.contactArray.next( maptoarray( Contacts ).sort( sortBy( 'name', false, false ) ) );
		}
		if ( this.contactID.getValue() === contactId ) {
			this.contact.next( contactPayload );
		}

	}

	public contactCreate = async ( contactPayload: Partial<Contact> ) => {
		let newcontact = null;
		if ( contactPayload.emails.length > 0 ) {
			contactPayload.emails = contactPayload.emails.filter( email => email.email !== '' );
		}
		try {
			newcontact = await firstValueFrom( this.http.post<Contact>( `${ BASE_API_URL }/contact`, contactPayload ) );
			newcontact.displayName = `${ newcontact.name } ${ newcontact.middleName } ${ newcontact.surname }`
		} catch ( error ) {
			this.toasterService.error( 'Failed to create the contact on the server' );
			console.error( error );
			throw error;
		}
		await this.syncCreateCache( newcontact );
		this.contactsGet();
		return newcontact;

	}
	public contactSplit = async ( contactFirstId: string, firstContact: Partial<Contact>, secondContact: Partial<Contact> ) => {

		let splitContacts = null;
		try {
			splitContacts = await firstValueFrom( this.http.post<Contact>( `${ BASE_API_URL }/contact/${ contactFirstId }`, { firstContact, secondContact } ) );
		} catch ( error ) {
			this.toasterService.error( 'Failed to create the contact on the server' );
			console.error( error );
			throw error;
		}
		await this.syncCreateCache( splitContacts );
		this.contactsGet();
		return splitContacts;
	}

	private syncCreateCache = async ( newContact: Contact ) => {

		const Contacts = this.contacts.getValue();
		Contacts[ newContact.id ] = newContact;
		this.contacts.next( Contacts );
		this.contactArray.next( maptoarray( Contacts ).sort( sortBy( 'name', false, false ) ) );

		if ( this.contactID.getValue() === newContact.id ) {
			console.error( 'Contactid should not match created Contact' );
			this.contact.next( newContact );
		}

	}

	public contactFetch = async ( contactId: string ) => {

		if ( contactId ) {
			try {
				return await firstValueFrom( this.http.get<Contact>( `${ BASE_API_URL }/contact/${ contactId }` ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
			} catch ( error ) {
				this.toasterService.error( 'Failed to fetch contact from the server' );
				console.error( error );
				throw error;
			}
		} else {
			this.toasterService.warning( 'contact fetch is requested without a contact ID' );
			throw new Error( 'contact fetch is requested without a contact ID' );
		}

	}
	public contactWithArraysFetch = async ( contactId: string ) => {

		if ( contactId ) {
			try {
				return await firstValueFrom( this.http.get( `${ BASE_API_URL }/contactWithArrays/${ contactId }` ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
			} catch ( error ) {
				this.toasterService.error( 'Failed to fetch contact from the server' );
				console.error( error );
				throw error;
			}
		} else {
			this.toasterService.warning( 'contact fetch is requested without a contact ID' );
			throw new Error( 'contact fetch is requested without a contact ID' );
		}

	}
	public waitForContacts = async () => {
		if ( Object.values( this.contacts.getValue() ).length > 0 ) {
			return;

		}
	}
	public getContactByUser = async ( userId: string ) => {
		try {
			return await firstValueFrom( this.http.get<Contact>( `${ BASE_API_URL }/users/${ userId }/contact` ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
		} catch ( error ) {
			this.toasterService.error( 'Failed to fetch contact from the server' );
			console.error( error );
			throw error;
		}
	}
	public syncUpsertCacheWith = async ( updatedContacts: Contact[] ) => {
		const contacts = this.contacts.getValue();
		updatedContacts.forEach( contact => ( contacts[ contact.id ] = contact ) );
		this.contacts.next( contacts );
		this.contactArray.next( maptoarray( contacts ).sort( sortBy( 'displayName' ) ) );


	}
	public contactBatchUpsert = async ( batch: Partial<Contact[]> ) => {
		try {
			const res = await firstValueFrom( this.http.patch<Contact[]>( `${ BASE_API_URL }/contact`, batch ) );
			// assumed batch insert
			const contacts = res;
			await this.syncUpsertCacheWith( contacts );
		} catch ( error ) {
			this.toasterService.error( 'Failed to updates unit batch' );
			console.error( error );
			throw error;
		}
		return true;
	}

	public referredToContactGet = async ( contactID ) => {
		try {
			const relations = await firstValueFrom( this.http.get<any[]>( `${ BASE_API_URL }/referredToContactGet/${ contactID }` ) );
			return relations;
		} catch ( err ) {
			console.error( err );
		}
	}
	public contactsMerge = async (	targetContactId:string,
	toBeRemovedContactId:string,
	selectedNameContactId:string,
	bankInfoContactId:string,
	trnContactId: string) => {
		try {
			const result = await firstValueFrom( this.http.put<Contact>( `${ BASE_API_URL }/v2/contact/merge`, { targetContactId, toBeRemovedContactId, selectedNameContactId, bankInfoContactId, trnContactId } ) );
			await this.syncUpdateCache( result.id, result );
			await this.contactsGet();
		} catch ( error ) {
			this.toasterService.error( 'Error merging contacts' );
			throw error;
		}
		return true;
	}

	public uploadContactImage = async ( fileList: any ): Promise<string> => {
		try {
			const formData = new FormData();
			fileList.forEach( fileDoc => {
				formData.append( fileDoc.id, fileDoc.logoImage );
			} );
			return await firstValueFrom( this.http.post<any>( `${ BASE_API_URL }/uploadContactImage`, formData ) );
		} catch ( error ) {
			this.toasterService.error( 'Failed to upload contact profile image' );
		}
	}

	public getcontact = async () => await getNonNullNonEmptyValue<Contact>( this.contact );
	public getcontacts = async () => await getNonNullNonEmptyValue<IMap<Contact>>( this.contacts );
	public getcontactArray = async () => await getNonNullNonEmptyValue<Contact[]>( this.contactArray );
	public getContactbyId = async ( id: string ): Promise<Contact> => ( ( await this.getcontacts() )[ id ] || { id, name: 'N/A' } as Contact );
	public getContactFunctions = async () => await getNonNullNonEmptyValue<IMap<PositionedItem>>( this.contactFunctions );
	public getContactFunctionsArray = async () => await getNonNullNonEmptyValue<PositionedItem[]>( this.contactFunctionsArray );

	public getContactsNoCache = async () => {
		return arraytomap( await this.contactsFetch() );
	}
	public getContactByIdNoCache = async ( id ) => {
		return await this.contactFetch( id );
	}

	public getProfileUpdateRequests = async ( msg?) => {
		try {
			const requests = await firstValueFrom( this.http.get<ProfileUpdateVM[]>( `${ BASE_API_URL }/profileUpdateRequests` ) );
			if ( msg ) {
				if ( ( requests || [] )?.filter( req => req.status === 'new' )?.length ) {
					console.log( requests )
					this.messageService.warn({sticky: true, message: msg})
				}
			}
			return requests;
		} catch ( error ) {
			console.error( error );
			this.messageService.error( {   title:'Error',message: 'Failed to Fetch Requests.', sticky: true } );
		}
	}

	public approveUpdateRequest = async ( selectedRequest ) => {
		try {
			await firstValueFrom( this.http.put( `${ BASE_API_URL }/approveUpdateRequest/${ selectedRequest.contact }`, selectedRequest ) );
			this.messageService.success( {   title:'Success',message: 'Request Approved & contact Updated Successfully.', sticky: true } );
		} catch ( error ) {
			console.error( error );
			this.messageService.error( {   title:'Error',message: 'Failed to Approve the Update Requests.', sticky: true } );
		}
	}

	public declineUpdateRequest = async ( selectedRequest ) => {
		try {
			await firstValueFrom( this.http.put( `${ BASE_API_URL }/declineUpdateRequest/${ selectedRequest.contact }`, selectedRequest ) );
			this.messageService.success( {   title:'Success',message: 'Request Declined Successfully.', sticky: true } );
		} catch ( error ) {
			console.error( error );
			this.messageService.error( {   title:'Error',message: 'Failed to Decline the Update Requests.', sticky: true } );
		}
	}

	public checkExistedContactEmail = async ( email: any ) => {
		try {
			if ( !email ) {
				this.messageService.error( {   title:'Validation error',message: 'Please Enter Email.', sticky: true } );
			}
			const existingEmail = await firstValueFrom( this.http.get<any>( `${ BASE_API_URL }/checkExistedContactEmail/${ email }` ) );
			if ( existingEmail.email ) {
				this.messageService.error( {   title:'Validation error',message: `${ email } Email is already exist.`, sticky: true } );
			}
			return existingEmail;
		} catch ( error ) {
			this.toasterService.error( 'Failed to check if the email is exist' );
			console.error( error );
			throw error;
		}
	}

	public async getContactByEmail ( email: string ) {
		try {
			return await firstValueFrom( this.http.get<Contact>( `${ BASE_API_URL }/fetchContactByEmail/${ email }` ).pipe( retry( MAX_API_CALL_RETRIES ) ) );
		} catch ( error ) {
			this.toasterService.error( 'Failed to fetch the contacts from the server' );
		}
	}

	public getContactPayments = async ( contact: string ) => {
		try {
			if ( !contact ) {
				this.messageService.error( {   title:'Validation error',message: 'Please Enter Contact.', sticky: true } );
			}
			const result = await firstValueFrom( this.http.get<ContactPayments>( `${ BASE_API_URL }/contactPayments/${ contact }` ) );
			return result;
		} catch ( error ) {
			this.toasterService.error( 'Failed to get contact payments' );
			console.error( error );
			throw error;
		}
	}
	public insertContactPayments = async ( contactPayments: ContactPayments ) => {
		try {
			if ( !contactPayments ) {
				throw 'Failed to save contact payments'
			}
			const body = contactPayments;
			const result = await firstValueFrom( this.http.post<ContactPayments>( `${ BASE_API_URL }/contactPayments/`, body ) );
			this.toasterService.success( 'Contact Payments Saved Successfully' )
			return result;
		} catch ( error ) {
			this.toasterService.error( 'Failed to save contact payments' );
			console.error( error );
			throw error;
		}
	}
	public fetchContactsByFunctionAndCommunity = async ( functionType: string, propertyGroup: string ) => {
		try {
			const result = await firstValueFrom( this.http.get<Contact[]>( `${ BASE_API_URL }/fetchContactsByFunctionAndCommunity/${ functionType }/${ propertyGroup }` ) );
			return result;
		} catch ( error ) {
			this.toasterService.error( 'Failed to get contacts' );
			console.error( error );
			throw error;
		}
	}

	public fetchContactCommunities = async ( contact?: string ) => {
		try {
			let result: any;
			if ( contact ) {
				result = await firstValueFrom( this.http.get<any[]>( `${ BASE_API_URL }/getContactCommunities/${ contact }` ) );
			} else {
				result = await firstValueFrom( this.http.get<any[]>( `${ BASE_API_URL }/getContactCommunities` ) );
			}
			return result;
		} catch ( error ) {
			this.toasterService.error( 'Failed to get contact communities' );
			console.error( error );
			throw error;
		}
	}

	public fetchContactsByIds = async ( ids: string[] ) => {
		try {
			const result = await firstValueFrom( this.http.get<Contact[]>( `${ BASE_API_URL }/getContacts`, { params: { ids } } ) );
			return result;
		} catch ( error ) {
			this.toasterService.error( 'Failed to get contacts' );
			console.error( error );
			throw error;
		}
	}
	public fetchCollectionManagers = async () => {
		try {
			const result = await firstValueFrom( this.http.get<Contact[]>( `${ BASE_API_URL }/fetchCollectionManagers` ) );
			return result;
		} catch ( error ) {
			this.toasterService.error( 'Failed to get contacts' );
			console.error( error );
			throw error;
		}

	}
	public findContact = async ( baseContactId: string, query: string ): Promise<Array<Contact>> => {
		try {
			const contacts = await firstValueFrom( this.http.post<Array<Contact>>( `${ BASE_API_URL }/v2/contact/searchContactsExcludingId`, {baseContactId, query} ) );
			return contacts;
		} catch ( err ) {
			this.toasterService.error( 'Error finding contact' );
			console.error( err );
		}
	}
}
